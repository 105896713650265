import React, { useState } from 'react';
import { NavLink} from 'react-router-dom';
import logoImage from './assets/Logo (2).png';
import Nav1 from './assets/Frame 1000003999.png';
import Nav2 from './assets/Frame 1000003999 (1).png';
import Nav3 from './assets/Frame 1000003999 (2).png';
import Nav4 from './assets/Frame 1000003999 (3).png';
import Nav5 from './assets/Frame 1000003999 (4).png';
import appStoreImage from './assets/Frame 1000004916.png';
import './App.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Navbar = ({ openLanguageModal }) => {
  const [hovered, setHovered] = useState(null); // Tracks hovered link for showing popups

  const handleMouseEnter = (item) => {
    setHovered(item);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  return (
    <nav className="navbar">
      <ul className="nav-links">
        <li className="logo-item">
          <NavLink to="/">
            <img src={logoImage} alt="Logo" className="logo-image" />
          </NavLink>
        </li>
        <div className="right-line">
          </div>

          <li
  className="popup-container relative"
  onMouseEnter={() => setHovered("products")}
>
<NavLink
  to="/home"
  className={`flex items-center space-x-2 relative group transition-colors duration-200 ${
    hovered === "products" ? "text-[#0056b3]" : "text-gray-500"
  }`}
  onMouseEnter={() => setHovered("products")}
>

    <span>Products</span>
    <div className="cursor-pointer">
      {hovered === "products" ? (
        <FaChevronUp className="text-[#0056b3] text-sm transition-colors duration-200" />
      ) : (
        <FaChevronDown className="text-gray-500 text-sm transition-colors duration-200 group-hover:text-[#0056b3]" />
      )}
    </div>
    <span
      className={`absolute bottom-[-2px] right-0.5 w-full h-[2px] bg-[#0056b3] transition-transform duration-200 ${
        hovered === "products" ? "scale-x-100" : "scale-x-0"
      }`}
    ></span>
  </NavLink>
  {hovered === "products" && (
    <div
      className="popup absolute top-full left-0 bg-white shadow-lg p-4"
      onMouseEnter={() => setHovered("products")}
      onMouseLeave={() => setHovered("")}
    >
      <div className="popup-column">
        <NavLink to="/car-owner" className="popup-item">
          <img src={Nav1} alt="Car Owner" />
          <div>
            <h4>Car Owner</h4>
            <p>Connect with trusted professionals for your car maintenance and repairs</p>
          </div>
        </NavLink>
        <NavLink to="/garage-owner" className="popup-item">
          <img src={Nav2} alt="Garage Owner" />
          <div>
            <h4>Garage Owner</h4>
            <p>Grow your business with real-time job requests and reviews from car owners</p>
          </div>
        </NavLink>
      </div>
      <div className="pop-app-store">
        <h2>Available on Apple store and Google play store</h2>
        <p>Click on the links below to download Aucarga mobile application</p>
        <div className="popup-app-store-icons">
          <img src={appStoreImage} alt="App Store" className="store-icon" />
        </div>
      </div>
    </div>
  )}
</li>
        <li
          className="popup-container"
          onMouseEnter={() => handleMouseEnter('resources')}
        >
  <NavLink
    to="/home"
    className={`flex items-center hover:text-[#0056b3] space-x-2 relative group transition-colors duration-200 ${
      hovered === "resources" ? "text-[#0056b3]" : "text-gray-500"
    }`}
    onMouseEnter={() => setHovered("resources")}
  >
      <span>Resources</span>
      <div className="cursor-pointer">
        {hovered === "resources" ? (
          <FaChevronUp className="text-[#0056b3] text-sm transition-colors duration-200" />
        ) : (
          <FaChevronDown className="text-gray-500 text-sm transition-colors duration-200 group-hover:text-[#0056b3]" />
        )}
      </div>

      <span
      className={`absolute bottom-[-2px] right-0.5 w-full h-[2px] bg-[#0056b3] transition-transform duration-200 ${
        hovered === "resources" ? "scale-x-100" : "scale-x-0"
      }`}
    ></span>
    </NavLink>
          {hovered === 'resources' && (
            <div className="popup2"
            onMouseEnter={() => handleMouseEnter('resources')} 
            onMouseLeave={handleMouseLeave} >
            <div className="popup-column">
            <NavLink to="https://blog.aucarga.com/" className="popup-item">
                <img src={Nav3} alt="Blogs" />
                <div>
                  <h4>Blogs</h4>
                  <p>Stay informed about all the latest updates on Aucarga</p>
                </div>
              </NavLink>
              <NavLink to="/docs" className="popup-item">
                <img src={Nav1} alt="Documentation" />
                <div>
                  <h4>Documentation</h4>
                  <p>Explore the instructions for getting started and being productive with Aucarga</p>
                </div>
              </NavLink>
              <NavLink to="/faqs" className="popup-item">
                <img src={Nav4} alt="Faqs" />
                <div>
                  <h4>FAQs</h4>
                  <p>Find quick answers to common question about our products, services and policies</p>
                </div>
              </NavLink>
              <NavLink to="/career" className="popup-item">
                <img src={Nav5} alt="Careers" />
                <div>
                  <h4>Careers</h4>
                  <p>We're thrilled to have you on board! Join the team today and be part of something great</p>
                </div> 
              </NavLink>
            </div>
            <div className="mt-20">
            <div className="pop-app-store2">
      <h2>Available on Apple store and Google play store</h2>
      <p>
      Click on the links below to download Aucarga
mobile application
      </p>
      <div className="popup-app-store-icons2">
        <img src={appStoreImage} alt="App Store" className="store-icon" />
      </div>
      </div>
    </div>
            </div>
          )}
        </li>

        <li>
        <NavLink
      to="/contact-us"
      className="text-gray-500 hover:text-[#0056b3] flex items-center space-x-2 relative group transition-colors duration-200"
    >
      <span>Contact us</span>
      <span className="absolute bottom-[-2px] right-0.5 w-full h-[2px] bg-[#0056b3] scale-x-0 group-hover:scale-x-100 transition-transform duration-200"></span>
      </NavLink>
         
        </li>
      </ul>

      <div className="auth-buttons">
      <a href="https://signup.aucarga.com" target="_blank" rel="noopener noreferrer">
      <button className="bg-[#EAECF0]  mr-4 text-[#003366] px-4 py-3 rounded-lg transition-all duration-300 
                   hover:bg-gradient-to-r hover:from-[#003366] hover:to-[#0056b3] hover:text-white hover:scale-105">
Try Demo
</button>
</a>
<button className="bg-[#003366] text-white px-4 py-3 rounded-lg transition-all duration-300 
                   hover:bg-gradient-to-r hover:from-[#003366] hover:to-[#0056b3] 
                   hover:scale-105">
  Download App
</button>
      </div>
    </nav>
  );
};

export default Navbar;
